// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-interness-theme-default-src-pages-404-js": () => import("./../../../node_modules/@interness/theme-default/src/pages/404.js" /* webpackChunkName: "component---node-modules-interness-theme-default-src-pages-404-js" */),
  "component---node-modules-interness-theme-default-src-pages-contact-js": () => import("./../../../node_modules/@interness/theme-default/src/pages/contact.js" /* webpackChunkName: "component---node-modules-interness-theme-default-src-pages-contact-js" */),
  "component---node-modules-interness-theme-default-src-pages-thanks-js": () => import("./../../../node_modules/@interness/theme-default/src/pages/thanks.js" /* webpackChunkName: "component---node-modules-interness-theme-default-src-pages-thanks-js" */),
  "component---node-modules-interness-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../node_modules/@interness/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---node-modules-interness-web-core-src-pages-datenschutz-social-mdx" */),
  "component---node-modules-interness-web-core-src-pages-info-js": () => import("./../../../node_modules/@interness/web-core/src/pages/---info.js" /* webpackChunkName: "component---node-modules-interness-web-core-src-pages-info-js" */),
  "component---node-modules-interness-web-core-src-templates-page-legal-js": () => import("./../../../node_modules/@interness/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---node-modules-interness-web-core-src-templates-page-legal-js" */),
  "component---src-pages-beschwerdebilder-allergien-unvertraeglichkeiten-mdx": () => import("./../../../src/pages/beschwerdebilder/allergien_unvertraeglichkeiten.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-allergien-unvertraeglichkeiten-mdx" */),
  "component---src-pages-beschwerdebilder-arthrose-rheuma-mdx": () => import("./../../../src/pages/beschwerdebilder/arthrose_rheuma.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-arthrose-rheuma-mdx" */),
  "component---src-pages-beschwerdebilder-chronische-darmerkrankung-mdx": () => import("./../../../src/pages/beschwerdebilder/chronische-darmerkrankung.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-chronische-darmerkrankung-mdx" */),
  "component---src-pages-beschwerdebilder-chronische-hauterkrankung-mdx": () => import("./../../../src/pages/beschwerdebilder/chronische-hauterkrankung.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-chronische-hauterkrankung-mdx" */),
  "component---src-pages-beschwerdebilder-diabetes-typ-2-mdx": () => import("./../../../src/pages/beschwerdebilder/diabetes-typ-2.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-diabetes-typ-2-mdx" */),
  "component---src-pages-beschwerdebilder-durchblutungsstoerung-mdx": () => import("./../../../src/pages/beschwerdebilder/durchblutungsstoerung.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-durchblutungsstoerung-mdx" */),
  "component---src-pages-beschwerdebilder-entgiftung-mdx": () => import("./../../../src/pages/beschwerdebilder/entgiftung.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-entgiftung-mdx" */),
  "component---src-pages-beschwerdebilder-erschoepfung-stress-mdx": () => import("./../../../src/pages/beschwerdebilder/erschoepfung_stress.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-erschoepfung-stress-mdx" */),
  "component---src-pages-beschwerdebilder-immunstaerkung-mdx": () => import("./../../../src/pages/beschwerdebilder/immunstaerkung.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-immunstaerkung-mdx" */),
  "component---src-pages-beschwerdebilder-rueckenbeschwerden-mdx": () => import("./../../../src/pages/beschwerdebilder/rueckenbeschwerden.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-rueckenbeschwerden-mdx" */),
  "component---src-pages-beschwerdebilder-vorsorge-praevention-mdx": () => import("./../../../src/pages/beschwerdebilder/vorsorge_praevention.mdx" /* webpackChunkName: "component---src-pages-beschwerdebilder-vorsorge-praevention-mdx" */),
  "component---src-pages-diagnoseverfahren-bioresonanz-testung-mdx": () => import("./../../../src/pages/diagnoseverfahren/bioresonanz-testung.mdx" /* webpackChunkName: "component---src-pages-diagnoseverfahren-bioresonanz-testung-mdx" */),
  "component---src-pages-diagnoseverfahren-blut-labor-mdx": () => import("./../../../src/pages/diagnoseverfahren/blut-labor.mdx" /* webpackChunkName: "component---src-pages-diagnoseverfahren-blut-labor-mdx" */),
  "component---src-pages-diagnoseverfahren-irisdiagnose-mdx": () => import("./../../../src/pages/diagnoseverfahren/irisdiagnose.mdx" /* webpackChunkName: "component---src-pages-diagnoseverfahren-irisdiagnose-mdx" */),
  "component---src-pages-diagnoseverfahren-stuhl-labor-mdx": () => import("./../../../src/pages/diagnoseverfahren/stuhl-labor.mdx" /* webpackChunkName: "component---src-pages-diagnoseverfahren-stuhl-labor-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-praxis-mdx": () => import("./../../../src/pages/praxis.mdx" /* webpackChunkName: "component---src-pages-praxis-mdx" */),
  "component---src-pages-therapien-begleitende-krebstherapie-mdx": () => import("./../../../src/pages/therapien/begleitende-krebstherapie.mdx" /* webpackChunkName: "component---src-pages-therapien-begleitende-krebstherapie-mdx" */),
  "component---src-pages-therapien-bioresonanz-therapie-mdx": () => import("./../../../src/pages/therapien/bioresonanz-therapie.mdx" /* webpackChunkName: "component---src-pages-therapien-bioresonanz-therapie-mdx" */),
  "component---src-pages-therapien-chiropraktik-mdx": () => import("./../../../src/pages/therapien/chiropraktik.mdx" /* webpackChunkName: "component---src-pages-therapien-chiropraktik-mdx" */),
  "component---src-pages-therapien-darmsanierung-mdx": () => import("./../../../src/pages/therapien/darmsanierung.mdx" /* webpackChunkName: "component---src-pages-therapien-darmsanierung-mdx" */),
  "component---src-pages-therapien-eigenblutbehandlung-mdx": () => import("./../../../src/pages/therapien/eigenblutbehandlung.mdx" /* webpackChunkName: "component---src-pages-therapien-eigenblutbehandlung-mdx" */),
  "component---src-pages-therapien-homoeopathie-mdx": () => import("./../../../src/pages/therapien/homoeopathie.mdx" /* webpackChunkName: "component---src-pages-therapien-homoeopathie-mdx" */),
  "component---src-pages-therapien-injekto-akupunktur-mdx": () => import("./../../../src/pages/therapien/injekto-akupunktur.mdx" /* webpackChunkName: "component---src-pages-therapien-injekto-akupunktur-mdx" */),
  "component---src-pages-therapien-neuraltherapie-mdx": () => import("./../../../src/pages/therapien/neuraltherapie.mdx" /* webpackChunkName: "component---src-pages-therapien-neuraltherapie-mdx" */),
  "component---src-pages-therapien-schroepfmassage-mdx": () => import("./../../../src/pages/therapien/schroepfmassage.mdx" /* webpackChunkName: "component---src-pages-therapien-schroepfmassage-mdx" */),
  "component---src-pages-therapien-vitamin-c-infusion-mdx": () => import("./../../../src/pages/therapien/vitamin-c-infusion.mdx" /* webpackChunkName: "component---src-pages-therapien-vitamin-c-infusion-mdx" */)
}

